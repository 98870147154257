<div class="icon-list-item case-event-list-item event {{caseEventDefinition.required ? 'required' : ''}} {{ caseEvent.archived ? 'disabled' : '' }}" (click)="onCaseEventClick()">
	<mwc-icon [innerHTML]="renderedTemplateListItemIcon" slot="item-icon"></mwc-icon>
	<div>
		<div [innerHTML]="renderedTemplateListItemPrimary|unsanitizeHtml"></div>
		<div [innerHTML]="renderedTemplateListItemSecondary|unsanitizeHtml" secondary></div>
	</div>
	<span *ngIf="!caseEventArchived">
		<button *appHasAPermission="let i;group:groupId; permission:'can_archive_events'" (click)="onArchiveEventClick(); $event.stopPropagation();" class="tangy-small-list-icon">
			<mwc-icon>archive</mwc-icon>
		</button>
	</span>
	<span *ngIf="caseEventArchived">
		<button *appHasAPermission="let i;group:groupId; permission:'can_unarchive_events'" (click)="onUnarchiveCaseClick(); $event.stopPropagation();" class="tangy-small-list-icon-alternate">
			<mwc-icon>unarchive</mwc-icon>
		</button>
	</span>
</div>